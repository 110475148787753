import React, { useEffect } from 'react'
import "../App.css"

const InverseMandelbulb = () => {
    useEffect(() => {
        window.location.href = 'https://youtu.be/YLvf93xwMnc'
    }, [])
    
    return (
        <div className=''>
            <h1 className="font-mono text-2xl italic font-black center-xy">Redirecting...
            <h2 className="font-mono text-lg not-italic font-normal">Surfing across the intertubes 🏄 </h2>
            </h1>
        </div>
    )
}

export default InverseMandelbulb

