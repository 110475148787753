import React, { useEffect } from 'react'
import "../App.css"


// on load, redirect to https://princeton.zoom.us/my/marvit

const Meet = () => {
    useEffect(() => {
        window.location.href = 'https://princeton.zoom.us/my/marvit'
    }, [])
    
    return (
        <div className=''>
            <h1 className="font-mono text-2xl italic font-black center-xy">Redirecting...
            <h2 className="font-mono text-lg not-italic font-normal">See you soon!</h2>
            </h1>
        </div>
    )
}

export default Meet
