import React, { useEffect } from 'react'
import "../App.css"

const Card = () => {

    return (
        <div className=''>
            #WIP
        </div>
    )
}

export default Card
