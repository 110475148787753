import '../App.css';
import { useSpring, animated, config, easings } from 'react-spring'
import React, { useState } from 'react'
import { Typewriter } from 'react-simple-typewriter'
import blue_gold_wisp_composited from '../blue_gold_wisp_composited.png';
import 'katex/dist/katex.min.css'
import Latex from 'react-latex-next'

function ArtPage() {

    const [showTypewriter, setShowTypewriter] = useState(false);

    const title_anim = useSpring({
        from: {
            opacity: 1,
            //alignItems: "center",
            marginRight: "0rem",
        },
        to: {
            opacity: 1,
            //marginRight: "20rem",
            //alignItems: "left",
        },
        delay: 1000,
        //config: config.stiff,
        config: {
            duration: 1000,
            easing: easings.easeInOutQuint,
        },
    })

    const subtitle_anim = useSpring({
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
        delay: 800,
        //config: config.molasses
        config: {
            duration: 1000,
            easing: easings.easeInOutQuint,
        },
        onRest: () => setTimeout(() => setShowTypewriter(true), 2000),
    })


    return (
        <>
            <div className="border-0 border-red-500 wrapper">
                <animated.div className="absolute title" style={title_anim}>
                    <span className="font-bold">hi!</span><span className="font-extralight"> i'm huxley.</span>
                </animated.div>
                <animated.div className="absolute mt-40 text-xl sub" style={subtitle_anim}>

                    {
                    /*i think math and code are beautiful. i wanted to share some of that beauty in a way anyone could see.*/
                    }

                    Words don't work well for some parts of my application, so I hosted them here instead.

                <p className="absolute w-full mt-4 text-base text-center typer">
                    {showTypewriter? <Typewriter
                    words={['I hope you enjoy!']}
                    cursor
                    delaySpeed={1000}
                    cursorStyle='_'
                    /> : <div>&nbsp;</div>}
                </p>
                </animated.div>


                <div className="sep"></div>

                <div className=""></div>

            </div>

            <div className="flex content-center justify-center pl-12 mb-5 text-3xl font-black text-center">
                <div>
                    I mentioned that I like to use code to visualize the beauty of math.
                    <div className="text-xl font-normal"> Here are some examples: </div>
                </div>
            </div>



            <div className="flex flex-row content-center justify-around border-0 border-red-500 vid-container">
                <iframe width="100%"
                    src="https://www.youtube.com/embed/KacPOAnbtjI"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    modestbranding="1"
                    className="rounded-lg"
                ></iframe>
            </div>

            <div className="flex flex-col desc">
                <p> This is a 3D generalization of the Mandelbrot set, called a Mandelbulb! </p>
                {"\n"}
                <p>
                    My code allows me to visualize arbitrary exponents on the fractal,
                    meaning that instead of just the normal mandelbrot iteration <Latex >{' $z_{n+1} = z_{n}^2+c$'}</Latex>,
                    we can explore <Latex >{' $z_{n+1} = z_{n}^r+c$'}</Latex>.

                    {"\n"}
                </p> <p>
                    This animation is made by changing that value of <Latex >{' $r$'}</Latex>, with one more added twist:
                    instead of positive values of<Latex >{' $r$'}</Latex>, we are seeing negative values with increasing magnitude.
                    {"\n"}

                    {"\n"}
                </p>
                <p>
                    {"\n"} <br />
                    <details>
                        <summary>
                        Click for a note to my fellow abstract algebra nerds on generalization to <Latex>{'$\\mathbb{R}^3$'}</Latex>
                        </summary>
                    Since generalizations of the complex numbers <Latex>{'$\\mathbb{C}$'}</Latex> to higher dimensions are always powers of 2, I'm using a bit of a hack to allow for the exponentiation of <Latex>$z$</Latex> called the White-Nylander Formula. While technically it doesn't form a Cayley–Dickson algebra, it still works. I could have jumped straight to quaternions—the next algebra in the sequence—and just rendered a 3D slice of the 4D fractal, but I find the 3D generalization especially beautiful.
                    </details>
                </p>
            </div>

            <div className="flex content-center justify-center pl-12 mb-5 text-2xl font-black text-center">
                <div>
                    Here's a version with an increasing positive exponent,
                </div>
            </div>

            <div className="flex flex-row justify-around border-0 border-red-500 svid-container">
                <iframe
                    width="70%"
                    src="https://www.youtube.com/embed/LpnGa-lps3A"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    modestbranding="1"
                    className="rounded-lg"
                ></iframe>
            </div>
                <div className="mt-5 im">
                   <p className="absolute em-t"> and here's my favorite frame from the first video. </p>
                    <img src={blue_gold_wisp_composited} />
                </div>


            <div className="flex content-center justify-center pl-12 mt-12 mb-5 text-2xl font-black text-center">
                <div>
                    Here's a top down view,
                </div>
            </div>

            <div className="flex flex-row justify-around border-0 border-red-500 mvid-container">
                <iframe
                    width="80%"
                    src="https://www.youtube.com/embed/YLvf93xwMnc"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    modestbranding="1"
                    className="rounded-lg"
                ></iframe>
            </div>













            <div className="flex content-center justify-center pl-12 mt-12 mb-5 text-2xl font-black text-center">
                <div>
                    and here's a version where I increase the number of iterations of the fractal.
                </div>
            </div>

            <div className="flex flex-row justify-around border-0 border-red-500 mvid-container">
                <iframe
                    width="80%"
                    src="https://www.youtube.com/embed/PeWfb6tpwbI"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    modestbranding="1"
                    className="rounded-lg"
                ></iframe>
            </div>


            <div className="flex flex-col desc">
                <p>
                    This is animating through different max values of
                    <Latex >{' $n$'}</Latex> in <Latex >{' $z_{n+1} = z_{n}^r+c$'}</Latex>, where <Latex >{' $r$'}</Latex> is held constant.
                </p>
                <p>
                    As <Latex >{' $n$'}</Latex> increases, the visualization becomes a better approximation of the theoretical infinitely complex fractal which can never truly be calculated.
                </p>
                <p>
                    <details>
                        <summary>
                        A note on parity
                        </summary>
                    Unlike with <Latex >{'$r$'}</Latex>, I can't really increase <Latex >{'$n$'}</Latex> continuously, since the number of iterations needs to be an integer. Hence we pop between the two archetypes of the fractal (the solid vs. the more gaseous version) corresponding to the parity of the number of iterations.
                    </details>
                </p>
            </div>




            <div className="flex content-center justify-center pl-12 mt-10 mb-5 text-3xl font-black text-center">
                <div>
                    Here's one of the promo videos I made with friends
                    <div className="text-xl font-normal">(for one of the apps I made with friends. You can find the app itself at
<a className="font-semibold text-blue-500" href="https://www.condution.com" target="_blank"> condution.com</a>)
                    </div>
                </div>
            </div>

            <div className="flex flex-row content-center justify-around mt-5 border-0 border-red-500 vid-container">
                <iframe width="100%"
                    src="https://www.youtube.com/embed/pScsFpcDdkg"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    modestbranding="1"
                    className="rounded-lg"
                ></iframe>
            </div>
            <div className="w-full mt-5 mb-5 text-xl text-center">
<Typewriter
                    words={['Thanks for checking this out!']}
                    cursor
                    delaySpeed={1000}
                    cursorStyle='_'
                    />
                </div>
        </>
    );
}

export default ArtPage;
