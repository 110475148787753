import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Art from './components/Art';
import Meet from './components/Meet';
import Card from './components/Card';
import InverseMandelbulb from "./components/InverseMandelbulb";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/art",
    element: <Art />,
  },
  {
    path: "/meet",
    element: <Meet />,
  },
  {
    path: "/card",
    element: <Card />,
  },
  //{
  //  path: "/resume",
  //  element: <Resume />,
  //},
  {
    path: "/inverse-mandelbulb",
    element: <InverseMandelbulb />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
